import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

export const SeeseeAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} color="primary">
      <Typography
        variant="h6"
        color="secondary"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  );
};
