import React from "react";
import { Admin, Resource, Layout } from "react-admin";
import { SchoolList } from "./pages/SchoolDashboard";
import { CreateSchool } from "./pages/CreateSchool"
import { LoginPage } from "./pages/LoginPage";
import { SeeseeAppBar } from "./components/SeeseeAppBar.js";
import { createMuiTheme } from "@material-ui/core/styles";
import SchoolIcon from "@material-ui/icons/School";

import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from "react-admin-firebase";

const config = {
  apiKey: "AIzaSyC8Fj_epwDlYHO4V-GR1J_w3FBOvxk7oTE",
  authDomain: "seesee-80f37.firebaseapp.com",
  databaseURL: "https://seesee-80f37.firebaseio.com",
  projectId: "seesee-80f37",
  storageBucket: "seesee-80f37.appspot.com",
  messagingSenderId: "466111747349",
  appId: "1:466111747349:web:32de01bd3529f073aa851c",
  measurementId: "G-RBNJ6HEXRF",
};

const options = {
  logging: false,
};

export const firebaseAuthProvider = FirebaseAuthProvider(config, options);
const dataProvider = FirebaseDataProvider(config, options);

const SeeseeLayout = (props) => <Layout {...props} appBar={SeeseeAppBar} />;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ffff56",
      main: "#ffea00",
      dark: "#c7b800",
      text: "#ffffff",
    },
    secondary: {
      light: "#484848",
      main: "#212121",
      dark: "#000000",
      text: "#ffffff",
    },
  },
});

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={firebaseAuthProvider}
      loginPage={LoginPage}
      layout={SeeseeLayout}
      theme={theme}
    >
      <Resource
        name="schools"
        list={SchoolList}
        create={CreateSchool}
        icon={SchoolIcon}
      />
    </Admin>
  );
}

export default App;
