import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput
} from "react-admin";

export const CreateSchool = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput label="School Display Name" source="displayName" />
            <DateInput
                label="Photo Due Date (YYYY-MM-DD)"
                source="dueDate"
                defaultValue={new Date()}
            />
            <TextInput label="Contact Email" source="contactEmail" />
            <TextInput label="School Address" source="address" />
            <label>
                *Please Make sure this link ends in a image extension (png, jpg, jpeg). Upload an image to <a href="https://console.firebase.google.com/u/1/project/seesee-80f37/storage/seesee-80f37.appspot.com/files"> Firebase Storage</a> if necessary.
            </label>
            <TextInput label="School Image Link" source="image" />
        </SimpleForm>
    </Create>
);