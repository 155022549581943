import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Button
} from "react-admin";
import { firebaseAuthProvider } from "../App";
const moment = require("moment");
const firebase = require("firebase");

const config = {
  apiKey: "AIzaSyC8Fj_epwDlYHO4V-GR1J_w3FBOvxk7oTE",
  authDomain: "seesee-80f37.firebaseapp.com",
  databaseURL: "https://seesee-80f37.firebaseio.com",
  projectId: "seesee-80f37",
  storageBucket: "seesee-80f37.appspot.com",
  messagingSenderId: "466111747349",
  appId: "1:466111747349:web:32de01bd3529f073aa851c",
  measurementId: "G-RBNJ6HEXRF",
};

var app = firebase.initializeApp(config);
var db = firebase.firestore(app);

export const SchoolList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="displayName" />
      <EmailField source="contactEmail" color="secondary" />
      <ZipButton />
      <CSVButton />
    </Datagrid>
  </List>
);

export const ZipButton = (props) => (
  <Button
    variant="contained"
    color="primary"
    onClick={() => requestZip(props.record.displayName, props.record.dueDate)}
    label="Download Images"
  />
);

export const CSVButton = (props) => (
  <Button
    variant="contained"
    color="primary"
    onClick={() => requestCSV(props.record.id)}
    label="Download Info CSV"
  />
)

async function requestZip(schoolName, schoolDate) {
  var user = await firebaseAuthProvider.checkAuth();
  var userRef = db.collection("users").doc(user.uid);
  userRef.get().then(function(doc) {
    if (doc.exists) {
        if(doc.data().admin === true) {
          generateZip(schoolName, schoolDate);
        } else {
          alert("You must be an admin to perform this function.");
        }
    } else {
        alert("Error getting current user");
    }
  }).catch(function(error) {
    console.log("Error getting document:", error);
    alert("Error authenticating user");
  });
}

function generateZip(schoolName, schoolDate) {
  const schoolID = schoolName.replace(/ /g,"");
  console.log(schoolID);
  console.log(schoolDate);

  let folderName =
    schoolID.replace(/ /g, "") +
    "__" +
    moment(schoolDate).format("MM_DD_YYYY");

  console.log(folderName);

  var zipFunction = firebase.functions().httpsCallable("zipPictures");
  zipFunction({ schoolFolder: folderName })
    .then(function () {
      alert("The images are being zipped. An email will be sent to the configured email within 10 mins.");
    })
    .catch(function (error) {
      alert(error);
    });
}

async function requestCSV(schoolID) {
  var user = await firebaseAuthProvider.checkAuth();
  var userRef = db.collection("users").doc(user.uid);
  userRef.get().then(function(doc) {
    if (doc.exists) {
        if(doc.data().admin === true) {
          createCSV(schoolID);
        } else {
          alert("You must be an admin to perform this function.");
        }
    } else {
        alert("Error getting current user");
    }
  }).catch(function(error) {
    console.log("Error getting document:", error);
    alert("Error authenticating user");
  });
}

function createCSV(schoolID) {
  const schoolRef = db.collection('schools').doc(schoolID);
  const users = db.collection("users");

  console.log("Generating CSV for school: " + schoolID);
  
  users.where('school', '==', schoolRef).get().then((schoolUsers) => {
    console.log("QUERY DONE");
    var rows = [["ID","NAME","ADDRESS", "CITY", "STATE", "ZIP", "EMAIL", "USER CREATION DATE"]];
    schoolUsers.forEach(function(childSnapshot) {
      var childData = childSnapshot.data();
      if(childData.name && childData.address) {
        rows.push([childSnapshot.id, 
                  childData.name, 
                  childData.address.address,
                  childData.address.city,
                  childData.address.state,
                  childData.address.zipCode,
                  childData.email,
                  childData.createdTime]);
      }
    });
    let csvContent = "data:text/csv;charset=utf-8," 
    + rows.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "school.csv");
    document.body.appendChild(link);
    link.click();
  });
}
